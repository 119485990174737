let timerduration = 1000
let timerEnded = false
let contentLoaded = false
let progress = 0
let slowInterval
const loadingEvent = new Event("loadingend");

let interval = setInterval(()=>{

  if (progress == 90) {
    if (!contentLoaded) {
      clearInterval(interval);
      timerEnded = true


      slowInterval = setInterval(()=>{
        if (progress < 99)
          increment()
      }, timerduration/2)
    }
  }

  if (progress == 99) {
    timerEnded = true

    if (contentLoaded) {
      increment()
      triggerEnd()
    }

    clearInterval(interval);
  } else {
    increment()
  }

}, timerduration/100)


document.addEventListener("DOMContentLoaded", () => {
  contentLoaded = true

  if (timerEnded) {
    clearInterval(slowInterval);
    document.getElementById('load-text').style.width = `78%`
    triggerEnd()
  }
});


function increment() {
  progress++
  let text = document.getElementById('load-text')

  if (text)
    text.style.width = `${progress*0.78}%`
}

function triggerEnd() {
  document.querySelector('#load').classList.add('init')

  setTimeout(()=>{
    document.dispatchEvent(loadingEvent);
  }, 600)
}